<template>
    <div id="job-list">
        <v-head title="本地中醫藥職位招聘"></v-head>
        <div class="job-search">
            <span class="icon-search"></span>
            <input type="text" class="keyword" placeholder="請輸入職位名稱/地區" v-model="keyword" v-on:keyup.enter="doSearch">
            <div class="btn-search" @click="doSearch">搜索</div>
        </div>
        <van-pull-refresh v-model="isRefresh" @refresh="onRefresh">
            <div class="content" ref="listView">
                <div class="empty" v-if="!isRefresh && !dataList.length">-- 已經全部加載 --</div>
                <router-link :to="`/job/details/${item.jobid}`" class="job-item" v-for="(item, index) in dataList" :key="index">
                    <div class="row-title">
                        <span class="job-type" v-text="item.employmentType"/>
                        <h1 class="title" v-text="item.title"></h1>
                        <span class="count">({{item.number}}名)</span>
                    </div>
                    <div class="job-location">
                        <span class="location-icon" v-if="item.location"></span>
                        <span class="location-text" v-text="item.location"></span>
                    </div>
                    <div class="job-intro">
                        <div class="intro-title">職位有效期</div>
                        <div class="intro-content" v-html="item.expiredate || '--'"></div>
                    </div>
                    <div class="job-intro">
                        <div class="intro-title">職位責任</div>
                        <div class="intro-content" v-html="item.jobdesc || '--'"></div>
                    </div>
                    <div class="job-intro">
                        <div class="intro-title">任職資格</div>
                        <div class="intro-content" v-html="item.requirement || '--'"></div>
                    </div>
                    <div class="clinic-info">
                        <img :src="item.cliniclogo" :alt="item.clinicname" class="logo">
                        <h2 class="name" v-text="item.clinicname"></h2>
                    </div>
                </router-link>
                <div class="load-more" v-if="isLoadMore">
                    <div class="lds-default"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                    <span class="text">正在加载...</span>
                </div>
                <div class="load-finished" v-if="isLoadFinished">已全部加載</div>
            </div>
		    <loading v-if="loading"/>
        </van-pull-refresh>
    </div>
</template>

<script>
	import vHead from '@/layouts/MobileHead.vue'
    import job_list from '@/views/mixins/job_list'
	import Loading from '@/layouts/Loading.vue'
	export default {
        mixins:[
            job_list,
        ],
        components: {
            vHead,
            Loading
        },
        data: function(){
            return {
                pageSize: 10,
                pageNo: 1,
                total: 0,
                dataList: [],
                isRefresh: true,
                area1List: [],
                area2List: [],
                area2Map: {},
                keyword: '',
                areaLevel1Id: undefined,
                areaLevel2Id: undefined,
                loading: true,
				isLoadMore: false,
				isLoadFinished: false,
                locationMap: {},
            }
        },
        mounted: function(){
            var hash = this.$route.hash
            hash = hash.replace('#', '')
            var list = hash.split('&')
            var key = list[0].split('=')[0]
            var value = list[0].split('=')[1] / 1
            if(key == 'page') {
                if(! isNaN(value)) {
                    this.pageNo = parseInt(value) < 1 ? 1 : parseInt(value)
                }
            }
			this.onRefresh()
        },
        created: async function(){
            //加载省市区
            var area1ListJson = sessionStorage.getItem('area1List')
            var area2MapJson = sessionStorage.getItem('area2Map')
            if(area1ListJson && area2MapJson) {
                this.area1List = JSON.parse(area1ListJson)
                this.area2Map = JSON.parse(area2MapJson)
            } else {
                this.area1List = Array.from(await this.$store.dispatch('getAreaList', {'langcode': 'B5'}));
                const map = {};
                for(let item of this.area1List) {
                    if(item.codeid == -1) {
                        map[item.codedesc] = []
                        continue
                    }
                    const _data = await this.$store.dispatch('getAreaList', {'langcode': 'B5', 'parentId': item.codeid});
                    map[item.codedesc] = _data;
                }
                this.area2Map = {...map};
                if(this.area1List.length) {
                    sessionStorage.setItem('area1List', JSON.stringify(this.area1List))
                    sessionStorage.getItem('area2Map', JSON.stringify(this.area2Map))
                }
            }
            if(this.area1List.length) {
                this.area2List = [];
                for(var item of this.area1List) {
                    for(var area2 of this.area2Map[item.codedesc]) {
                        this.area2List.push({name: area2.codedesc, areaLevel2Id: area2.codeid, areaLevel1Id: item.codeid})
                    }
                }
            }
        },
        methods: {
            onPageChanged: function(pageNo){
                var path = this.$route.fullPath
                var hash = this.$route.hash
                if(hash && path.indexOf(hash) != -1) {
                    path = path.replace(hash, '')
                }
                this.$router.push({path:  path+'#page='+pageNo},(e)=>{});
            },
            setAreaName: async function()  {
                var areaList = await this.$store.dispatch('getAreaList', {'langcode': 'B5'})
                if(this.dataList.length) {
                    for(let i=0; i<this.dataList.length; i++) {
                        const data = this.dataList[i]
                        const params = {
                            areaLevel1Id: data.arealevel1id,
                            areaLevel2Id: data.arealevel2id,
                            langcode: 'B5',
                        }
                        const area1 = areaList.find((v)=>v.codeid == data.arealevel1id)
                        var _area2 = this.locationMap[`${data.arealevel1id}-${data.arealevel2id}`]
                        const area2 = _area2 || await this.$store.dispatch('getAreaDetail', params)
                        this.locationMap[`${data.arealevel1id}-${data.arealevel2id}`] = area2
                        data.location = `${area1.codedesc} ${area2.codedesc}`
                    }
                    this.dataList = [...this.dataList]
                }
            },
            doSearch: function() {
                this.pageNo = 1;
                this.areaLevel1Id = undefined
                this.areaLevel2Id = undefined
                if(this.keyword) {
                    var index = this.area2List.findIndex((d)=>d.name==this.keyword);
                    if(index != -1) {
                        this.areaLevel1Id = this.area2List[index].areaLevel1Id
                        this.areaLevel2Id = this.area2List[index].areaLevel2Id
                    }
                }
                this.onRefresh()
            },
            onRefresh: async function(){
                this.isLoadFinished = false
                this.employmentTypeList = await this.$store.dispatch('getEmploymentType', {}) || []
                const params = {
                    pageNum: this.pageNo,
                    pageSize: this.pageSize,
                    areaLevel1Id: this.areaLevel1Id,
                    areaLevel2Id: this.areaLevel2Id,
                }
                if(this.keyword) {
                    params['title'] = this.keyword
                }
				this.loadData(params, (data, error)=>{
					this.loading = false
					this.isRefresh = false
					this.dataList = ((data && data.list) || []).map((v)=>{
                        var employmentTypeData = v.employmenttypeid && this.employmentTypeList && this.employmentTypeList.find((e)=>e.codeid == v.employmenttypeid)
                        var employmentType = employmentTypeData && employmentTypeData.codeDesc;
                        return {...v, employmentType};
                    });
					var total = (data && data.total) || 0
					if(total > 0) {
						this.maxPage = Math.floor((total + this.pageSize - 1)/this.pageSize)
						if(this.pageNo >= this.maxPage) {
							this.isLoadFinished = true
						}
					} else {
						if(!error) {
							this.isLoadFinished = true
						}
					}
                    this.setAreaName()
				})
            },
            async handleScroll (event) {
				if((window.innerHeight + window.pageYOffset) > this.$refs.listView.clientHeight) {
					// 加载更多
					if(!this.loading && !this.isLoadMore && this.pageNo < this.maxPage) {
						this.isLoadMore = true
						this.loading = true
                        const params = {
                            pageNum: this.pageNo+1,
                            pageSize: this.pageSize,
                            areaLevel1Id: this.areaLevel1Id,
                            areaLevel2Id: this.areaLevel2Id,
                        }
                        if(this.keyword) {
                            params['title'] = this.keyword
                        }
						this.loadData(params, (data, error)=>{
							this.loading = false
							this.isLoadMore = false
							this.dataList = this.dataList.concat(((data && data.list) || []).map((v)=>{
                                var employmentTypeData = v.employmenttypeid && this.employmentTypeList && this.employmentTypeList.find((e)=>e.codeid == v.employmenttypeid)
                                var employmentType = employmentTypeData && employmentTypeData.codeDesc;
                                return {...v, employmentType};
                            }));
							if(!error) {
								this.pageNo = this.pageNo + 1
								var total = (data && data.total) || 0
								if(total > 0) {
									var maxPage = Math.floor((total + this.pageSize - 1)/this.pageSize)
									if(this.pageNo >= maxPage) {
										this.isLoadFinished = true
									}
								}
							}
                            this.setAreaName()
						})
					}
				}
			}
		},
		created () {
			window.addEventListener('scroll', this.handleScroll);
		},
		destroyed () {
			window.removeEventListener('scroll', this.handleScroll);
		},
    }
</script>

<style lang="scss" scoped>
    #job-list {
        width: 100%;
	    padding-top: 83px;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
    }
    .content {
        flex: 1;
        width: 100%;
        min-height: 100vh;
        position: relative;
        margin: 0px auto;
        margin-top: 40px;
    }

    .content .empty {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 50px;
        z-index: 0;
        color: #9E9E9E;
        font-size: 24px;
    }

    .job-item {
        position: relative;
        display: block;
        padding: 32px 26px;
        background-color: #fff;
        border-radius: 8px;
        margin-bottom: 24px;
    }

    .job-item > .row-title {
        display: flex;
        flex-direction: row;
        line-height: 50px;
    }

    .job-item .job-location {
        margin-top: 20px;
    }
    
    .job-item > .row-title .job-type {
        display: inline-block;
        line-height: 42px;
        height: 42px;
        padding: 0px 8px;
        border-radius: 2px;
        margin: auto 0px;
        margin-right: 10px;
        font-size: 28px;
        font-weight: bold;
        color: #FFFFFF;
        background-color: #2B99A6;
    }

    .job-item > .row-title .title {
        color: #231F20;
        font-size: 48px;
        font-weight: bold;
        line-height: 48px;
    }

    .job-item > .row-title .count {
        color: #9E9E9E;
        font-size: 48px;
        line-height: 1.0;
        margin-left: 24px;
        margin-right: 70px;
    }

    .job-item .location-icon {
        display: inline-block;
        width: 28px;
        height: 32px;
        margin-top: 10px;
        margin-right: 12px;
        background-image: url(../../../assets/imgs/pc/jobs/icon_dingwei@2x.png);
        background-size: 28px 32px;
        vertical-align: bottom;
    }
    .job-item .location-text {
        color: #2B99A6;
        font-size: 32px;
        line-height: 32px;
        margin-top: 10px;
    }

    .job-item .job-intro {
        margin-top: 36px;
        display: flex;
        flex-direction: column;
    }

    .job-item .job-intro .intro-title {
        color: #231F20;
        font-size: 28px;
        line-height: 28px;
    }

    .job-item .job-intro .intro-content {
        color: #969696;
        font-size: 28px;
        margin-top: 8px;
    }

    .job-item .job-intro:first-child {
        margin-bottom: 35px;
    }

    .job-search {
        display: flex;
        flex-direction: row;
        margin: 16px 24px;
        background-color: #fff;
        height: 56px;
        border-radius: 16px;
    }

    .job-search > .icon-search {
        display: inline-block;
        width: 21px;
        height: 21px;
        margin: 18px 15px 17px 19px;
        background-image: url(../../../assets/imgs/mobile/doctor-list/icon_search@2x.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }

    .job-search > input.keyword {
        height: 56px;
        flex: 1;
        font-size: 28px;
        font-weight: 400;
        color: #231F20;
    }

    .job-search > input.keyword::placeholder {
        font-size: 24px;
        color: #969696;
        font-weight: 400;
    }

    .job-search > .btn-search {
        opacity: 1;
        width: 114px;
        height: 56px;
        line-height: 56px;
        text-align: center;
        color: #fff;
        font-weight: bold;
        font-size: 32px;
        background-color: #36C4D0;
        border-top-right-radius: 16px;
        border-bottom-right-radius: 16px;
    }

    .job-search > .btn-search::selection {
        color: #fff;
    }

    .job-search > .btn-search:active {
        opacity: 9;
    }

    
    #job-list .clinic-info {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 20px;
    }

    #job-list .clinic-info > img {
        display: inline-block;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        padding: 4px;
        object-fit: contain;
        border: 1px solid #f3f3f3;
    }

    #job-list .clinic-info > img[src=""] {
        padding: 0;
        position: relative;
    }

    #job-list .clinic-info > img[src=""]::after {
        content: '';
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: #f1f1f1;
        position: absolute;
        left: 0;
        top: 0;
    }

    #job-list .clinic-info > .name {
        color: #666;
        font-size: 32px;
        font-weight: bold;
        margin-left: 8px;
    }

    
	.load-finished {
		height: 44px;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		position: relative;
		font-size: 24px;
		font-weight: 400;
		color: #99999991;
	}

	.load-finished::before {
		content: '';
		width: 120px;
		height: 1px;
		background-color: #99999991;
		right: -130px;
		margin-right: 20px;
		top: 50%;
		transform: translateY(-50%);
	}

	.load-finished::after {
		content: '';
		width: 120px;
		height: 1px;
		background-color: #99999991;
		left: -130px;
		top: 50%;
		margin-left: 20px;
		transform: translateY(-50%);
	}

	// loadmore
	.load-more {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
	}

	.load-more > .text {
		margin-left: 24px;
	}


	.lds-default {
		display: inline-block;
		position: relative;
		width: 80px;
		height: 80px;
	}
	.lds-default div {
		position: absolute;
		width: 6px;
		height: 6px;
		background: #36C4D0;
		border-radius: 50%;
		animation: lds-default 1.2s linear infinite;
	}
	.lds-default div:nth-child(1) {
		animation-delay: 0s;
		top: 37px;
		left: 66px;
	}
	.lds-default div:nth-child(2) {
		animation-delay: -0.1s;
		top: 22px;
		left: 62px;
	}
	.lds-default div:nth-child(3) {
		animation-delay: -0.2s;
		top: 11px;
		left: 52px;
	}
	.lds-default div:nth-child(4) {
		animation-delay: -0.3s;
		top: 7px;
		left: 37px;
	}
	.lds-default div:nth-child(5) {
		animation-delay: -0.4s;
		top: 11px;
		left: 22px;
	}
	.lds-default div:nth-child(6) {
		animation-delay: -0.5s;
		top: 22px;
		left: 11px;
	}
	.lds-default div:nth-child(7) {
		animation-delay: -0.6s;
		top: 37px;
		left: 7px;
	}
	.lds-default div:nth-child(8) {
		animation-delay: -0.7s;
		top: 52px;
		left: 11px;
	}
	.lds-default div:nth-child(9) {
		animation-delay: -0.8s;
		top: 62px;
		left: 22px;
	}
	.lds-default div:nth-child(10) {
		animation-delay: -0.9s;
		top: 66px;
		left: 37px;
	}
	.lds-default div:nth-child(11) {
		animation-delay: -1s;
		top: 62px;
		left: 52px;
	}
	.lds-default div:nth-child(12) {
		animation-delay: -1.1s;
		top: 52px;
		left: 62px;
	}
	@keyframes lds-default {
		0%, 20%, 80%, 100% {
			transform: scale(1);
		}
		50% {
			transform: scale(1.5);
		}
	}
</style>
